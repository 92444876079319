.right_menu {
  box-shadow: 7px 7px 50px rgba(0, 0, 0, 0.05);
  position: sticky !important;
  right: 0;
  top: 60px;
  height: calc(100vh - 60px);
  background: white;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    //width: 6px !important;
    //height: 6px !important;
    width: 0;
  }
  //&::-webkit-scrollbar-thumb {
  //  background: #d9d9d9;
  //  border-radius: 10px;
  //}
  .custom_menu {
    padding: 5px 0;
    height: auto;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  :global(.ant-menu-item) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 82px;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin: 0 !important;
    // border-radius: 10px;
    // margin-inline: 4px;
    // margin-block: 4px;
    // margin-bottom: 4px !important;
  }
  :global(.ant-menu .ant-menu-item .ant-menu-item-icon +span) {
    margin-inline-start: 0;
    width: 90px;
    text-align: center;
    white-space: break-spaces;
    margin-top: 5px;
  }
  :global(.ant-menu-item:not(.ant-menu-item-selected):hover) {
    background-color: #fff !important;
    // margin: 2px 9px 6px !important;
    box-shadow: -14px 20px 40px 0px rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.15);
  }
  span {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    color: #2C3782;
  }
  // :global(.ant-menu .ant-menu-item-divider) {
  //   margin: 0 auto ;
  //   width: 50%;
  //   min-width: 50%;
  //   border-block-start: 1px solid rgba(44, 55, 130, 0.3);
  // }
  :global(.ant-menu-item-disabled) {
    opacity: 0.5;
  }
  :global(.ant-menu-item-disabled):hover {
    // margin-inline: 4px !important;
    // margin-block: 4px !important;
    // margin-bottom: 4px !important;
    box-shadow: unset !important;
    border: unset !important;
  }
  .divider{
    margin: 0 auto;
    width: 50%;
    min-width: 50%;
    border-block-start: 1px solid rgba(44, 55, 130, 0.3);
    // opacity: 0;
    background-color: #2C3782;
    display:none;
  }
}
