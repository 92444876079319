@import "../../assets/style/mixins.less";

@padding-sider: 15px;
@sider-width-collapse: calc(@padding-sider * 2 + 30px);
@sider-width-full: 300px;

.container {
  height: 100vh;
  width: @sider-width-collapse !important;
  max-width: @sider-width-collapse !important;
  min-width: @sider-width-collapse !important;
  padding: 0px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;

  * {
    color: #fff;
  }

  .sider-ctn {
    position: absolute;
    top: 0;
    left: 0;
    background-color: @navy1;
    width: @sider-width-collapse;
    height: 100vh;
    transition: all 0.15s ease-in-out;
    box-shadow: 0 0 10px #00000025;
    overflow: hidden;

    //&:hover {
    //  width: @sider-width-full;
    //
    //  .sider .menu {
    //    .group-menu.open {
    //      display: block;
    //    }
    //
    //    .submenu.open {
    //      .title {
    //        color: #fff;
    //      }
    //      .icon * {
    //        filter: brightness(200%);
    //      }
    //
    //      .toggle-btn * {
    //        color: #fff;
    //      }
    //    }
    //  }
    //}

    .sider {
      width: @sider-width-full;
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;

      .avatar-ctn {
        padding-block: 15px;
      }

      .logo-ctn {
        min-height: @header-height;
        display: flex;
        align-items: center;
        gap: @padding-sider;
        padding-inline: @padding-sider;
        padding-block: 10px;
        // padding-bottom: 50px;
        // height: 105px;
        // border-bottom: 1px solid #ffffff10;

        span {
          font-size: 16px;
        }
      }

      .deepcare-ctn {
        .logo-ctn;
        // position: absolute;
        // bottom: 0;
        background-color: @navy1;
        width: 300px;
        padding-block: 17px;
        padding-inline: 12px;
      }

      .menu {
        // height: calc(100vh - @header-height * 2);
        overflow: auto;
        // padding-block: @padding-sider;
        flex: 1;

        /* width */
        &::-webkit-scrollbar {
          width: 6px !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #dadce0;
          border-radius: 10px;
        }

        .menu-item {
          padding-block: 15px;
          cursor: pointer;
          padding-inline: @padding-sider;
          padding-inline: calc(@padding-sider + 3px);
          display: flex;
          align-items: center;
          gap: calc(@padding-sider + 3px);

          &,
          .title {
            // transition: all 0.15s ease-in-out;
          }

          .icon {
            margin-block: -2px -5px;
          }

          .title {
            color: #8f99d3;
            font-weight: 600 !important;
            flex-grow: 100;
          }

          &:hover,
          &.active {
            background-color: @main-color;
            color: #fff;

            .title {
              color: #fff;
            }
            .icon * {
              filter: brightness(200%);
            }
          }
        }

        .submenu {
          .toggle-btn * {
            color: #8f99d3;
            font-size: 13px;
          }

          &:hover,
          &.active {
            .toggle-btn * {
              color: #fff;
            }
          }

          .menu-item;

          &:hover,
          &.active {
            background-color: transparent;
          }
        }

        .group-menu {
          display: none;

          &.group-menu-level-1 {
            .menu-item,
            .submenu {
              padding-left: calc(@padding-sider + 25px);
            }
          }
        }
      }
    }
  }

  .hover {
    width: @sider-width-full;

    .sider .menu {
      .group-menu.open {
        display: block;
      }

      .submenu.open {
        .title {
          color: #fff;
        }
        .icon * {
          filter: brightness(200%);
        }

        .toggle-btn * {
          color: #fff;
        }
      }
    }
  }
}