@import "../../assets/style/mixins.less";

.container {
  height: @header-height !important;
  position: sticky !important;
  top: 0;
  display: flex;
  align-items: center;
  // border-bottom: 1px solid #f0f0f0;
  box-shadow: 0 0 5px #00000025;
  z-index: 5;

  * {
    margin-bottom: 0 !important;
  }

  &.main-header {
    z-index: 3;
  }

  .wrapper-left {
    min-width: 239px;
    height: @header-height;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    padding-inline: 15px;

    &:hover {
      background-color: #00000005;
    }

    &.right {
      border-right: 1px solid #f0f0f0;
    }

    &.left {
      border-left: 1px solid #f0f0f0;
    }

    &.grow {
      flex-grow: 1;
      justify-content: flex-start;
      cursor: unset;

      &:hover {
        background-color: unset;
      }
    }

    .row-info-payment {
      background-color: #F5F7FB;
      margin-left: 30px;
      width: 100%;
      height: 35px;
      border-radius: 7px;
      .info-payment {
        border-right: 1px solid #E7E7E7;
        display: flex;
        align-items: center;
        padding-left: 15px !important;
        .text {
          font-size: 13px;
          color : #2C3782;
          line-height: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .wrapper {
    // min-width: @header-height;
    height: @header-height;
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    padding: 0 15px 0 7px;

    &:hover {
      background-color: #00000005;
    }

    &.right {
      border-right: 1px solid #f0f0f0;
    }

    &.left {
      border-left: 1px solid #f0f0f0;
    }

    &.grow {
      flex-grow: 1;
      justify-content: flex-start;
      cursor: unset;

      &:hover {
        background-color: unset;
      }
    }

    .row-info-payment {
      background-color: #F5F7FB;
      margin-left: 30px;
      width: 100%;
      height: 35px;
      border-radius: 7px;
      .info-payment {
        border-right: 1px solid #E7E7E7;
        display: flex;
        align-items: center;
        padding-left: 15px !important;
        .text {
          font-size: 13px;
          color : #2C3782;
          line-height: 16px;
          font-weight: 600;
        }
      }
    }
  }

  .icon {
    * {
      font-size: 18px;
    }

    &.color {
      color: @main-color;
    }
  }

  .name {
    margin-bottom: 0;
    color: @navy1;
    // margin-left: 10px;
    white-space: nowrap;
  }

  .input {
    height: 36px;
    border-radius: 50px;
    max-width: 400px;

    .icon {
      margin-right: 5px;
    }
  }

  .carets {
    display: flex;
    flex-direction: column;
  }
}

.menu {
  box-shadow: 0 0 10px #00000025;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 0;

  .user {
    padding: 10px 15px;
  }

  .menu-item {
    color: @navy1;
    padding: 12px 15px;
    cursor: pointer;

    &:hover,
    &.active {
      background-color: @main-color;
      color: #fff;
      svg {
        path:nth-of-type(even),
        path:nth-of-type(odd) {
          fill: #fff;
        }
        
      }
    }
  }

  .version {
    padding: 10px 15px;
    color: @main-color;
  }
}

.sub-popup {
  top: 0;
  right: calc(100% + 5px) !important;
}

.export-queue-btn {
  background-color: #ebedff !important;
  border: none !important;
  color: @navy1 !important;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-right: 15px;
}

.avatar {
  object-fit: contain;
}
.total-num {
  background-color: @main-color;
  color: #fff;
  padding-inline: 5px;
  border-radius: 5px;
  height: 28px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-routes {
  display: flex;
  flex-direction: column;
  gap: 12px;

  padding: 15px 12px;
  justify-content: space-around;
  .route-change-option {
    height: fit-content;
    align-items: center;
    .radio-content {
      display: flex;
      align-items: center;
      color: @navy1;
    }
    .icon-route-select {
      display: flex;
        position: relative;
        background-color: #F6F7FB;
        width: 32px;
        height: 32px;
        border-radius: 10px;
        align-items: center;
        margin-right: 4px;
      svg {
        width: 20px;
        height: 20px;
        text-align: center;
        margin: 0 7px;
      }
    }
  }
}

.icon-home {
  color: #45BB8B !important;
}

.popup-container {
  border-radius: 18px !important;
}

.exam-header {
  margin-left: 50px;
  .btn {
    min-width: 144px;
    background-color: #F3F5F9;
    border: none;
    color: navy;
    border-radius: 8px;
    &:disabled {
      background-color: #fcfcfc !important;
      color: #ccc;
    }
  }
  :global(.ant-btn-primary) {
    background-color: @navy1;
    color: #fff;
  }
}

